import React, { useEffect, useRef } from 'react'
import Card from '../../components/card/card.js'
import Scaffold from '../../components/scaffold'
import './projects.css'
import threatdefencelogo from './threatdefencelogo.png';
import awslogo from './awsimage.png';

function Projects() {

  const trackRef = useRef(null)
  /* -- Had to add extra lines for touch events -- */

  useEffect(() => {
    const track = trackRef.current

    track.dataset.mouseDownAt = "0"
    track.dataset.prevPercentage = "0"

    const rect = track.getBoundingClientRect()
    const innerWidth = rect.right - rect.left

    const handleOnDown = e => {
      track.dataset.mouseDownAt = e.clientX
    }

    const handleOnUp = () => {
      track.dataset.mouseDownAt = "0"
      track.dataset.prevPercentage = track.dataset.percentage
      console.log(track.dataset)
    }

    const handleOnMove = e => {
      if (track.dataset.mouseDownAt === "0") return

      const mouseDelta = parseFloat(track.dataset.mouseDownAt) - e.clientX,
        maxDelta = innerWidth / 1.5

      const percentage = (mouseDelta / maxDelta) * -100,
        nextPercentageUnconstrained = parseFloat(track.dataset.prevPercentage) + percentage,
        nextPercentage = Math.max(Math.min(nextPercentageUnconstrained, 0), -100)

      track.dataset.percentage = nextPercentage

      track.animate({
        transform: `translateX(${nextPercentage}%)`
      }, { duration: 1200, fill: "forwards" })

      for (const card of track.children) {
        card.animate({
          transform: `translateX(${nextPercentage * -1 - 50}%)`
        }, { duration: 1200, fill: "forwards" })
      }
    }

    const onmousedown = e => handleOnDown(e)
    const ontouchstart = e => handleOnDown(e.touches[0])
    const onmouseup = e => handleOnUp(e)
    const ontouchend = e => handleOnUp(e.touches[0])
    const onmousemove = e => handleOnMove(e)
    const ontouchmove = e => handleOnMove(e.touches[0])

    track.addEventListener("mousedown", onmousedown)
    track.addEventListener("touchstart", ontouchstart)
    window.addEventListener("mouseup", onmouseup)
    window.addEventListener("touchend", ontouchend)
    window.addEventListener("mousemove", onmousemove)
    window.addEventListener("touchmove", ontouchmove)

    return () => {
      window.removeEventListener("mousedown", onmousedown)
      window.removeEventListener("touchstart", ontouchstart)
      window.removeEventListener("mouseup", onmouseup)
      window.removeEventListener("touchend", ontouchend)
      window.removeEventListener("mousemove", onmousemove)
      window.removeEventListener("touchmove", ontouchmove)
    }
  }, [])

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('show')
        }
      })
    })

    const hiddenElements = document.querySelectorAll('.hidden')
    hiddenElements.forEach((el) => observer.observe(el))

    return () => {
      hiddenElements.forEach((el) => observer.unobserve(el))
    }
  }, [])

  return (
    <Scaffold watermark={"Projects."} title={
      <h1 style={{ fontSize: "2rem", color: "white", userSelect: "none", marginTop: "2em" }}>
        My <span style={{ color: "white", backgroundColor: "#8c52ff", borderRadius: "2em", paddingLeft: "0.25em", paddingRight: "0.25em", paddingBottom: "0.1em" }}>Projects</span> & <span style={{ color: "white", backgroundColor: "#5271ff", borderRadius: "2em", paddingLeft: "0.25em", paddingRight: "0.25em", paddingBottom: "0.1em" }}>Certifications</span>
      </h1>
    }>

      <div style={{ height: "100%", width: "100%", position: "relative" }}>
        <div style={{ overflow: "hidden", height: "100%", width: "100%" }}>
          <div style={{ height: "100%", width: "100%", transform: "translateX(50%)", userSelect: "none"}}>
            <div style={{ display: "flex", flexDirection: "row", gap: "4em", alignItems: "center", height: "100%", width: "max-content" }} ref={trackRef}>

              <div style={{ transform: "translateX(-50%)" }}>
                <Card>
                  <div style={{ width: "33em", height: "29em", position: "relative" }} className="card">
                    <h1 style={{ marginBottom: "0em" }}>Threat Defence</h1>
                    <h2 style={{ marginLeft: "1em", whiteSpace: "nowrap" }}>Incident and Response Bootcamp</h2>
                    <div style={{ textAlign: "left", fontSize: "1.1em" }}>
                      <p>Over a 5-week intensive program, I gained hands-on experience in simulating cyber attacks and incident response. I utilised the ThreatDefence console to analyse logs and reconstruct cyber attacks, while navigating real-life scenarios like ransomware and data breaches. I learned to craft effective Incident Response reports using the MITRE ATT&CK Framework, and gained insights into regulatory compliance requirements and industry standards.</p>
                    </div>
                    <div style={{ fontFamily: "Roboto Mono" }} className='see-more'>
                      <a className="link"style={{ textDecoration: "none", color: "black" }} target="_blank" href="https://www.linkedin.com/posts/threatdefence_uts-threatdefence-cyber-bootcamp-graduation-activity-7193718969260728322-UKfn?utm_source=share&utm_medium=member_desktop" rel="noopener noreferrer">
                        More Info Found Here
                      </a>
                    </div>
                    <img src={threatdefencelogo} alt="ThreatDefence" style={{ position: "absolute", bottom: 10, left: 0, height: '1.5em', flexShrink: 0, borderRadius: "10%" }} />
                  </div>
                </Card>

              </div>


              <div style={{ transform: "translateX(-50%)" }}>
                <Card>
                  <div style={{ width: "33em", height: "29em", position: "relative" }} className="card">
                    <h1 style={{ marginBottom: "0em" }}>Amazon Web Services</h1>
                    <h2 style={{ marginLeft: "1em", whiteSpace: "nowrap" }}>Cloud Practitioner Certification</h2>
                    <div style={{ textAlign: "left", fontSize: "1.1em" }}>
                      <p>The AWS Cloud Practitioner certification is an entry-level credential from Amazon Web Services (AWS), confirming foundational knowledge in cloud computing and AWS services. It covers core concepts like cloud principles, AWS infrastructure, basic architectural best practices, security, compliance, and economic models. Designed for newcomers to AWS and cloud computing, it equips individuals with essential skills to understand and use AWS services effectively. This certification signifies readiness for cloud-based solutions and serves as a stepping stone for pursuing more advanced AWS certifications and roles in cloud computing.</p>
                    </div>
                    <div style={{ fontFamily: "Roboto Mono" }} className='see-more'>
                      <a className="link"style={{ textDecoration: "none", color: "black" }}href="https://aws.amazon.com/certification/certified-cloud-practitioner/" target="_blank" rel="noopener noreferrer">
                        More Info Found Here
                      </a>
                    </div>

                    <img src={awslogo} alt="aws" style={{ position: "absolute", bottom: 10, left: 0, height: '5em', flexShrink: 0, borderRadius: "10%" }} />
                  </div>
                </Card>

              </div>

            </div>
          </div>
        </div>
        <div style={{ position: "absolute", height: "100%", width: "100%", top: 0, backgroundImage: "linear-gradient(to right, #1c1d22 0%, transparent 15%, transparent 85%, #1c1d22 100%)", pointerEvents: "none" }} />
      </div>

    </Scaffold>
  );
}

export default Projects;
