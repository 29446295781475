import React from 'react';
import Card from '../../components/card/card';
import Scaffold from '../../components/scaffold';

function Profile() {

  const handleDownload = () => {
    const pdfPath = '/resume.pdf';  
    const link = document.createElement('a');
    link.href = process.env.PUBLIC_URL + pdfPath;  
    link.download = 'ryankennedyresume.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Scaffold watermark={"About Me."} title={
      <h1 style={{ fontSize: "2rem", color: "white", userSelect: "none", marginTop: "2em" }}>
        About <span style={{ color: "white", backgroundColor: "#8c52ff", borderRadius: "2em", paddingLeft: "0.25em", paddingRight: "0.25em", paddingBottom: "0.1em" }}>Me</span>
      </h1>
    }>
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "100%", gap: "15em", overflow: "hidden" }}>
        <div style={{ height: "90%" }}>
          <Card>
            <div style={{ width: "30em", height: "20em" }}>
              <h1>About</h1>
              <p style={{ textAlign: "justify" }}>I am Ryan Kennedy, soon to be a Security Engineer at Canva. Currently, I am a 20-year-old Cyber Security student at the University of Technology Sydney (UTS), where I am actively involved in the Cybersec Society (CSEC). My passion for security drives me to constantly challenge myself through Capture The Flag (CTF) competitions as well as participating in security workshops, attending industry conferences (will be at BSides this year!), and stay updated with the latest trends and threats in the cybersecurity field.</p>
              <div onClick={handleDownload} style={{ cursor: 'pointer' }}>Download my Resume!</div>
            </div>
          </Card>
        </div>
        <div style={{ height: "30%" }}>
          <Card>
            <div style={{ width: "20em", height: "20em" }}>
              <h1>My Skills</h1>
            </div>
          </Card>
        </div>
      </div>
    </Scaffold>
  );
}

export default Profile;
