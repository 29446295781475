import React from 'react';
import { Link } from 'react-router-dom';
import Card from '../../components/card/card';
import Scaffold from '../../components/scaffold';
import { GoArrowUpRight } from "react-icons/go";
import "./home.css"


function Home() {

    return (
        <Scaffold bottombar={false}>
            <div style={{ display: "grid", gridTemplateRows: "1fr auto 1fr", placeContent: "center", height: "100%", width: "100%", overflow: "hidden" }}>
                <div style={{ gridRow: "2" }} >
                    <Card>
                        <div style={{ width: "25em", height: "12em", position: "relative"}} className='homeCard'>
                            <h1 style={{ fontSize: "2rem", color: "white", userSelect: "none" }} className='homeh1'>
                                Hey, I'm <span style={{ color: "white", backgroundColor: "#00bf63", borderRadius: "2em", paddingLeft: "0.25em", paddingRight: "0.25em", paddingBottom: "0.1em" }}>Ryan</span>
                            </h1>
                            <h1 style={{ fontSize: "2rem", color: "white", userSelect: "none" }} className='homeh1'>
                                I'm a <span style={{ color: "white", backgroundColor: "#5271ff", borderRadius: "2em", paddingLeft: "0.25em", paddingRight: "0.25em", paddingBottom: "0.1em" }}>Security Student</span>
                            </h1>
                            <h1 style={{ fontSize: "2rem", color: "white", userSelect: "none" }} className='homeh1'>
                                soon to be at <span className='span' style={{ color: "white", backgroundColor: "#8c52ff", borderRadius: "2em", paddingLeft: "0.25em", paddingRight: "0.25em", paddingBottom: "0.1em" }} onClick={() => window.open('https://www.canva.com/', '_blank')} >Canva<GoArrowUpRight /></span>
                            </h1>
                        </div>
                    </Card>
                </div>




                <div style={{ gridRow: "3", placeSelf: "center", fontSize: "1.5rem", fontFamily: "Roboto Mono", color: "white", opacity: "70%", userSelect: "none" }} className='see-more'>
                    <Link to="/profile" className="custom-link">
                        See More -&gt;
                    </Link>
                </div>

            </div>

        </Scaffold>
    );

}

export default Home;
