import React from 'react';


function Card({ children }) {
    return (
        <div style={{ position: "relative", width: "max-content", padding: "0.5em" }}>
            <div style={{ position: "absolute", backgroundColor: "#505258", height: '100%', width: '100%', margin: "0.5em", borderRadius: "2em", zIndex: -1 }} />
            <div style={{ position: "absolute", top: 0, left: 0, backgroundColor: "#ABB1B8", height: '100%', width: '100%', borderRadius: "2em", zIndex: -1 }} />
            <div style={{ marginLeft: "2em", marginRight: "2em", textAlign: "center" }}>
                {children}
            </div>
        </div>
    );
}

export default Card;
