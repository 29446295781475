// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/home';
import Profile from './pages/profile';
import Projects from './pages/projects';
import Work from './pages/work';

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/work" element={<Work />} />
        </Routes>
      </Router>
      <div style={{ position: "absolute", height:"100%", width: "100%", top: "0", left: "0", pointerEvents: "none", backgroundImage: "linear-gradient(to right, #262729 1px, transparent 1px), linear-gradient(to bottom, #262729 1px, transparent 1px)", backgroundSize: "4em 4em", zIndex: "-20"}} />
    </>
  );
}

export default App;
