import React, { useState, useEffect } from 'react';
import Scaffold from '../../components/scaffold';
import './work.css';
import atlassianLogo from '../images/atlassian_logo.jpg';
import canvaLogo from '../images/canva.png';
import adviceregtechLogo from '../images/adviceregtech.jpg';
import shoreLogo from '../images/shore.jpg';

function Work() {
    const [isAtTop, setIsAtTop] = useState(true);
    const [isAtBottom, setIsAtBottom] = useState(false);

    useEffect(() => {
        const handleScroll = (event) => {
            const target = event.target;
            const isTop = target.scrollTop === 0;
            const isBottom = target.scrollHeight - target.scrollTop === target.clientHeight;
            setIsAtTop(isTop);
            setIsAtBottom(isBottom);
        };

        const scrollContainer = document.getElementById('scroll-container');
        scrollContainer.addEventListener('scroll', handleScroll);

        return () => scrollContainer.removeEventListener('scroll', handleScroll);
    }, []);

    const getMaskImage = () => {
        if (isAtTop) {
            return 'linear-gradient(to bottom, white 0%, white 95%, transparent 100%)';
        }
        if (isAtBottom) {
            return 'linear-gradient(to top, white 0%, white 95%, transparent 100%)';
        }
        return 'linear-gradient(to bottom, transparent 0%, white 5%, white 95%, transparent 100%)';
    };

    return (
        <Scaffold watermark={"Experience."} title={
            <h1 style={{ fontSize: "2rem", color: "white", userSelect: "none", marginTop: "2em" }}>
                Job <span style={{ color: "white", backgroundColor: "#8c52ff", borderRadius: "2em", paddingLeft: "0.25em", paddingRight: "0.25em", paddingBottom: "0.1em" }}>Experience</span>
            </h1>
        }>

            <div id="scroll-container" style={{ maskImage: getMaskImage(), maskMode: "alpha", height: "100%", overflowY: "scroll", display: "flex", justifyContent: "center" }}>
                <div style={{ display: "grid", gridTemplateColumns: "max-content max-content 1fr", gridAutoRows: "max-content", color: "white", columnGap: "1rem", rowGap: "0.5rem", maxWidth: "70%" }}>

                    {/* Canva */}
                    
                    <div style={{ position: "relative", justifySelf: "end", backgroundColor: "#505258", height: "min-content", padding: "0.25rem", alignSelf: "center", marginRight: "1rem", gridArea: "1/1" }}>
                        <div style={{ position: "absolute", zIndex: "-100", height: "calc(1lh * sqrt(pow(1, 2) / 2) + 1rem * sqrt(pow(0.5, 2) / 2))", right: "0", width: "calc(1lh * sqrt(pow(1, 2) / 2) + 1rem * sqrt(pow(0.5, 2) / 2))", transform: "rotate(-45deg)", transformOrigin: "top right", backgroundColor: "#505258", top: "0" }} />
                        2025
                    </div>

                    <img src={canvaLogo} alt="Canva" style={{ width: '2.5rem', gridArea: "1/2", placeSelf: "center", alignSelf: "center" }} />
                    <div style={{ display: "flex", alignItems: "center", flexDirection: "column", placeItems: "center", gridArea: "2/2" }}>
                        <div style={{ height: "100%", width: "0.25em", backgroundColor: "#c1c5ca", borderRadius: "4em" }}></div>
                    </div>

                    <div style={{ gridArea: "1/3", alignSelf: "center" }}>
                        <h1 style={{ margin: "0", gridRow: "1" }}>Security Engineer Intern</h1>
                    </div>

                    <div style={{ gridArea: "2/3" }}>
                        <h2 style={{ fontWeight: "normal", marginTop: "0" }}>Canva</h2>
                        <div>Job Description</div>
                    </div>

                    {/* Atlassian */}
                    <div style={{ position: "relative", justifySelf: "end", backgroundColor: "#505258", height: "min-content", padding: "0.25rem", alignSelf: "center", marginRight: "1rem", gridArea: "3/1" }}>
                        <div style={{ position: "absolute", zIndex: "-100", height: "calc(1lh * sqrt(pow(1, 2) / 2) + 1rem * sqrt(pow(0.5, 2) / 2))", right: "0", width: "calc(1lh * sqrt(pow(1, 2) / 2) + 1rem * sqrt(pow(0.5, 2) / 2))", transform: "rotate(-45deg)", transformOrigin: "top right", backgroundColor: "#505258", top: "0" }} />
                        2024
                    </div>

                    <img src={atlassianLogo} alt="Atlassian" style={{ width: '2.3rem', gridArea: "3/2", placeSelf: "center", alignSelf: "center", borderRadius: "0.3rem" }} />
                    <div style={{ display: "flex", alignItems: "center", flexDirection: "column", placeItems: "center", gridArea: "4/2" }}>
                        <div style={{ height: "100%", width: "0.25em", backgroundColor: "#c1c5ca", borderRadius: "4em" }}></div>
                    </div>

                    <div style={{ gridArea: "3/3", alignSelf: "center" }}>
                        <h1 style={{ margin: "0", gridRow: "1" }}>IT System Administrator Intern | WPT</h1>
                    </div>

                    <div style={{ gridArea: "4/3" }}>
                        <h2 style={{ fontWeight: "normal", marginTop: "0" }}>Atlassian</h2>
                        <div>
                            <p> - Onboarding/Offboarding of employee's and hardware shipments/reclaims</p>
                            <p> - On-Site Event Support for Intentional Gatherings (audio/visual support, microphones, Zoom rooms etc) </p>
                            <p>- Manage and troubleshoot operating systems, including Windows Server, Linux, and Unix, addressing issues promptly to minimize downtime. </p>
                            <p> - Replacements of Devices </p>
                            <p> - Manage user accounts, permissions, and access controls to ensure data confidentiality and system integrity </p>
                        </div>
                    </div>

                    {/* SHORE */}
                    <div style={{ position: "relative", justifySelf: "end", backgroundColor: "#505258", height: "min-content", padding: "0.25rem", alignSelf: "center", marginRight: "1rem", gridArea: "5/1" }}>
                        <div style={{ position: "absolute", zIndex: "-100", height: "calc(1lh * sqrt(pow(1, 2) / 2) + 1rem * sqrt(pow(0.5, 2) / 2))", right: "0", width: "calc(1lh * sqrt(pow(1, 2) / 2) + 1rem * sqrt(pow(0.5, 2) / 2))", transform: "rotate(-45deg)", transformOrigin: "top right", backgroundColor: "#505258", top: "0" }} />
                        2022
                    </div>

                    <img src={shoreLogo} alt="SHORE" style={{ width: '2.3rem', gridArea: "5/2", placeSelf: "center", alignSelf: "center", borderRadius: "0.3rem" }} />
                    <div style={{ display: "flex", alignItems: "center", flexDirection: "column", placeItems: "center", gridArea: "6/2" }}>
                        <div style={{ height: "100%", width: "0.25em", backgroundColor: "#c1c5ca", borderRadius: "4em" }}></div>
                    </div>

                    <div style={{ gridArea: "5/3", alignSelf: "center" }}>
                        <h1 style={{ margin: "0", gridRow: "1" }}>Technical Support Officer</h1>
                    </div>

                    <div style={{ gridArea: "6/3" }}>
                        <h2 style={{ fontWeight: "normal", marginTop: "0" }}>SHORE</h2>
                        <div>
                            <p> - Understanding of Service Desk/Helpdesk processes</p>
                            <p> - Supporting Staff and Students on both Mac and Windows to diagnose and fix hardware and software issues</p>
                            <p> - Handovers and setup of windows computers to staff</p>
                            <p> - Use of Microsoft based computer environments including windows and office systems</p>
                            <p> - Customer service skills including the ability to support users of varying technical skills</p>
                            <p> - Handling of physical assets to and from places</p>
                        </div>
                    </div>

                    {/* Advice RegTech */}
                    <div style={{ position: "relative", justifySelf: "end", backgroundColor: "#505258", height: "min-content", padding: "0.25rem", alignSelf: "center", marginRight: "1rem", gridArea: "7/1" }}>
                        <div style={{ position: "absolute", zIndex: "-100", height: "calc(1lh * sqrt(pow(1, 2) / 2) + 1rem * sqrt(pow(0.5, 2) / 2))", right: "0", width: "calc(1lh * sqrt(pow(1, 2) / 2) + 1rem * sqrt(pow(0.5, 2) / 2))", transform: "rotate(-45deg)", transformOrigin: "top right", backgroundColor: "#505258", top: "0" }} />
                        2022
                    </div>

                    <img src={adviceregtechLogo} alt="Advice RegTech" style={{ width: '2.5rem', gridArea: "7/2", placeSelf: "center", alignSelf: "center", borderRadius: "0.3rem" }} />
                    <div style={{ display: "flex", alignItems: "center", flexDirection: "column", placeItems: "center", gridArea: "8/2" }}>
                        <div style={{ height: "100%", width: "0.25em", backgroundColor: "#c1c5ca", borderRadius: "4em" }}></div>
                    </div>

                    <div style={{ gridArea: "7/3", alignSelf: "center" }}>
                        <h1 style={{ margin: "0", gridRow: "1" }}>Junior Developer and Business Analyst</h1>
                    </div>

                    <div style={{ gridArea: "8/3" }}>
                        <h2 style={{ fontWeight: "normal", marginTop: "0" }}>Advice RegTech</h2>
                        <div>
                            <p> - Trainee business analyst and Junior Development tasks supporting software configuration and testing and including basic code development and assistance to the mid/senior developers</p>
                            <p> - Use of GitHub to test features for future releases </p>
                            <p> - Basic programming in various forms including test code and database editing </p>
                            <p> - Use of miro to design front end login system </p>
                        </div>
                    </div>
                </div>
            </div>
        </Scaffold>
    );
}

export default Work;
