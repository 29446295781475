import React, { useEffect, useState } from 'react';
import Bottombar from './bottombar/bottombar';
import './scaffold.css'

function Scaffold({ children, title, watermark, bottombar }) {

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 1);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div style={{ position: "relative", display: "grid", gridTemplateColumns: "1fr", gridTemplateRows: "max-content auto max-content", height: '100vh', width: '100vw', gap: "3em" }}>
      <div style={{ position: "absolute", color: "white", left: "1rem", bottom: "2rem", fontSize: "10rem", fontWeight: "bold", userSelect: "none", opacity: "5%", zIndex: "0", fontFamily: "Times New Roman" }}className={`watermarkfadein ${isVisible ? 'watermarkfade-in' : ''}`}>
        {watermark}
      </div>

      {/* Top Title */}
      <div style={{ gridRowStart: 1, placeSelf: "center" }}>
        {title}
      </div>

      {/* middle section*/}
      <div style={{ height: "100%", width: "100%", gridRowStart: 2, display: "flex", justifyContent: "center", alignItems: "center", overflow: "auto" }} className={`fadein ${isVisible ? 'fade-in' : ''}`} >
        {children}
      </div>

      {/* bottom bar */}
      {(bottombar ?? true) ? <div style={{ gridRowStart: 3, placeSelf: "center" }}>
        <div style={{ marginBottom: "2em", width: "min-content" }}>
          <Bottombar />
        </div>
      </div> : null}


    </div>
  );
}

export default Scaffold;
