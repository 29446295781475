import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { BiHomeAlt } from "react-icons/bi";
import { PiPaintBrushDuotone, PiSuitcaseSimple } from "react-icons/pi";
import { VscAccount } from "react-icons/vsc";
import { TiSocialLinkedin } from "react-icons/ti";
import { MdOutlineEmail } from "react-icons/md";
import { GoArrowUpRight } from "react-icons/go";
import './bottombar.css';

function BottomBarButton({ children, link, title }) {
  const location = useLocation();

  return (
    <div className="button-container" style={{ display: "flex", alignItems: "center", flexDirection: "column", position: "relative" }}>
      <Link to={link} style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
        <button style={{ height: "4em", width: "4em", marginTop: "1em", marginBottom: "0.375em", borderRadius: "1em", border: "none" }}>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", fontSize: "2.5em" }}>
            {children}
          </div>
        </button>
      </Link>
      <div className="tooltip">{title}</div>
      <div style={{ backgroundColor: "white", width: "50%", height: "0.25em", borderRadius: "1em", marginBottom: "0.375em", opacity: location.pathname === link ? "0.5" : "0", transition: "opacity 1s" }} />
    </div>
  );
}

function Bottombar() {
  const handleMailClick = () => {
    window.location.href = 'mailto:ryan@ryankennedy.au';
  };

  return (
    <div>
      <div style={{ position: "relative", marginBottom: "2em", width: "min-content", zIndex: "2" }}>
        <div style={{ position: "absolute", backgroundColor: "#505258", height: '100%', width: '100%', margin: "0.3em", borderRadius: "2em", zIndex: -1 }} />
        <div style={{ position: "absolute", top: 0, left: 0, backgroundColor: "#ABB1B8", height: '100%', width: '100%', borderRadius: "2em", zIndex: -1 }} />
        <div style={{ display: "flex", marginLeft: "3em", marginRight: "3em", gap: "1em", alignItems: "center" }}>
          <BottomBarButton link="/" title="Home">
            <BiHomeAlt />
          </BottomBarButton>

          <BottomBarButton link="/profile" title="Profile">
            <VscAccount />
          </BottomBarButton>

          <BottomBarButton link="/work" title="Work">
            <PiSuitcaseSimple />
          </BottomBarButton>

          <BottomBarButton link="/projects" title="Projects">
            <PiPaintBrushDuotone />
          </BottomBarButton>

          <div style={{ height: "2em", width: "0.25em", backgroundColor: "#c1c5ca", borderRadius: "4em" }}></div>
          <div className="button-container" style={{ display: "flex", alignItems: "center", flexDirection: "column", position: "relative" }}>
            <button style={{ height: "4em", width: "4em", marginTop: "1em", marginBottom: "1em", borderRadius: "1em", border: "none" }} onClick={() => window.open('https://www.linkedin.com/in/ryansarirkennedy/', '_blank')}>
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", fontSize: "3em" }}>
                <TiSocialLinkedin />
              </div>
            </button>
            <div className="tooltip" style={{display: "flex", alignItems: "end"}}>ryansarirkennedy<GoArrowUpRight /></div>
          </div>
          <div className="button-container" style={{ display: "flex", alignItems: "center", flexDirection: "column", position: "relative" }}>
            <button style={{ height: "4em", width: "4em", marginTop: "1em", marginBottom: "1em", borderRadius: "1em", border: "none" }} onClick={handleMailClick} >
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", fontSize: "2.5em" }}>
                <MdOutlineEmail />
              </div>
            </button>
            <div className="tooltip" style={{display: "flex", alignItems: "end"}}>ryan@ryankennedy.au<GoArrowUpRight /></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Bottombar;
